


import request from "@/utils/request.js";



// /api/Wechat/GetUserInfo?urls=Subscribe
export function GetUserInfo(params) {
  return request({
    url: "/api/Wechat/GetUserInfo",
    method: "get",
    params
    // data: params,
  });
}

// GET /api/Wechat/GetWxUserInfo
// 获取微信配置信息
export function GetWxUserInfo(params) {
  return request({
    url: "/api/Wechat/GetWxUserInfo",
    method: "get",
    params
    // data: params,
  });
}

// POST /api/Wechat/SetWxUserInfo
// 设置微信配置信息
export function SetWxUserInfo(params) {
  return request({
    url: "/api/Wechat/SetWxUserInfo",
    method: "post",
    params,
    data: params,
  });
}