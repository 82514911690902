<template>
  <div class="content_box theme_bgText Subscribe">
    <div class="user_ifon">
      <div class="head-portrait"><img :src="userInfo.pic" alt=""></div>
      <span>{{userInfo.nickname}}</span>
    </div>
    <div class="portrait-info">
      <p>订阅服务请绑定用户信息</p>
      <van-button style="width:50px;" @click="showModelOp" type="primary" color="#CCCCCC" round size="mini">设置</van-button>
    </div>
    <div class="portrait_config">
      <p class="tilte">预报预警</p>
      <ul class="box">
        <li>
          <span>天气预报</span>
          <van-button style="width:50px;" @click="dingyueFun('tqyb',0)" v-if="gz_config.tqyb" type="primary" color="#1CD66C" round size="mini">已订阅</van-button>
          <van-button style="width:50px;" @click="dingyueFun('tqyb',1)" v-else type="primary" color="#FEA929" round size="mini">未订阅</van-button>
        </li>
        <li>
          <span>临近预报</span>
          <van-button style="width:50px;" @click="dingyueFun('qxzl',0)" v-if="gz_config.qxzl" type="primary" color="#1CD66C" round size="mini">已订阅</van-button>
          <van-button style="width:50px;" @click="dingyueFun('qxzl',1)" v-else type="primary" color="#FEA929" round size="mini">未订阅</van-button>
        </li>
        <li>
          <span>预警信息</span>
          <van-button style="width:50px;" @click="dingyueFun('wxts',0)" v-if="gz_config.wxts" type="primary" color="#1CD66C" round size="mini">已订阅</van-button>
          <van-button style="width:50px;" @click="dingyueFun('wxts',1)" v-else type="primary" color="#FEA929" round size="mini">未订阅</van-button>
        </li>
        <!-- <li>
          <span>预警信号</span>
          <van-button style="width:50px;" type="primary" color="#FEA929" round size="mini">设置</van-button>
          <van-button style="width:50px;" type="primary" color="#FEA929" round size="mini">已订阅</van-button>
          <van-button style="width:50px;" type="primary" color="#1CD66C" round size="mini">未订阅</van-button>
        </li> -->
      </ul>
    </div>
    <div class="sk_remind">
      <div class="tilte">
        <label for="">实况提醒</label>
        <ol class="">
          <li>未订阅</li>
          <li class="dy_state">已订阅</li>
        </ol>
      </div>
      <ul class="portrait_list">
        <li class="list_item" v-for="item of portraitList" :key="item.name">
          <div class="what_el">
            <img :src="item.img" alt="">
            <span>{{ item.name }}</span>
          </div>
          <van-popover :placement="item.index>3?'top':'bottom'" v-model="item.open" trigger="click" :actions="item.actions" @select="e => showDropDown(e, item)">
            <template #reference>
              <div class="drop-down-box">
                <span :class="item.portrait!== '未订阅'?'ydy_zt': 'dy_zt'">{{ item.portrait }}</span>
                <van-icon class="drop-down" name="play" />
              </div>
            </template>
          </van-popover>
        </li>
        <li></li>
      </ul>
    </div>
    <van-dialog v-model="showcancel" title="订阅设置" :showCancelButton="false" :showConfirmButton="false">
      <div class="mianModel">
        <van-form ref="form">
          <van-field
            v-model="formData.s_nickname"
            name="用户名"
            label="用户名"
            placeholder="请输入用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
            v-model="formData.s_phone"
            type="text"
            name="手机号"
            label="手机号"
            placeholder="请输入手机号"
            :rules="[{ required: true, message: '请输入手机号' }]"
          />
        </van-form>
      </div>
      <div class="btnsModel">
          <!-- <van-button type="default" @click="showcancel = false">取消</van-button>
          <van-button block type="info" native-type="submit">提交</van-button> -->
        <van-button type="default" @click="showcancel = false">取消</van-button>
        <van-button color="#66CBFF" type="primary" @click="baocunModel">保存</van-button>
      </div>
    </van-dialog>
     <van-overlay z-index="10000" :show="overlayLoading" ><van-loading class="wrapper" type="spinner" /></van-overlay>
  </div>
</template>

<script>
// import myPicker from '@/components/myPicker'
// import { drawAQL } from "./airQuality.js"
import { GetUserInfo,GetWxUserInfo,SetWxUserInfo } from "./api.js"
export default {
  components: {
    // myPicker
  },
  name: "Subscribe",
  data() {
    return {
      overlayLoading:false,
      showcancel:false,
      // 通过 actions 属性来定义菜单选项
      // actions: [
      //   { text: '已订阅' },
      //   { text: '未订阅' }
      // ],
      portraitList: {
        zgqw:{index:1, name: '最高气温', img: require('@/assets/newImg/grdy1.png'), open: false, portrait: '未订阅', key:'zgqw', 
        actions:[
          {text:'未订阅',id:0},
          {text:'超过35℃',id:1},
          {text:'超过38℃',id:2},
          {text:'超过40℃',id:3},
        ]},
        zdqw:{index:2, name: '最低气温', img: require('@/assets/newImg/grdy2.png'), open: false, portrait: '未订阅', key:'zdqw' 
        ,actions:[
          {text:'未订阅',id:0},
          {text:'超过-10℃',id:1},
          {text:'超过-20℃',id:2},
          {text:'超过-30℃',id:3},
        ]},
        xsjs:{index:3, name: '小时降水', img: require('@/assets/newImg/grdy3.png'), open: false, portrait: '未订阅', key:'xsjs',
        actions:[
          {text:'未订阅',id:0},
          {text:'超过10mm',id:1},
          {text:'超过30mm',id:2},
          {text:'超过50mm',id:3},
        ]},
        ssfs:{index:4, name: '实时风速', img: require('@/assets/newImg/grdy4.png'), open: false, portrait: '未订阅', key:'ssfs',
        actions:[
          {text:'未订阅',id:0},
          {text:'6级(10.8m/s)',id:1},
          {text:'8级(17.2m/s)',id:2},
        ] },
        njd:{index:5, name: '能见度', img: require('@/assets/newImg/grdy5.png'), open: false, portrait: '未订阅', key:'njd',
        actions:[
          {text:'未订阅',id:0},
          {text:'小于1000m',id:1},
          {text:'小于500m',id:2},
          {text:'小于200m',id:3},
        ]},
      },
      formData:{
          s_nickname:'',
          s_phone:'',
      },
      gz_config:{
        tqyb:0, // 天气预报
        qxzl:0, // 气象专栏
        wxts:0, // 温馨提示
        // yjxh:0, // 预警信号
        zgqw:0, // 最高气温
        zdqw:0, // 最低气温
        xsjs:0, // 小时降水
        ssfs:0, // 实时风速
        njd:0 // 能见度
      }
    }
  },
  created() {
    this.GetUserData();
    this.GetWxUserFun();
  },
  mounted() {

  },
  methods: {
    // 订阅
    dingyueFun(key,num){
      console.log(key,num)
      this.gz_config[key] = num;
      console.log(key,num)
      this.baocunModel()
    },
    // 打开设置
    showModelOp(){
      this.showcancel = true;
    },
    async GetWxUserFun(){
      this.overlayLoading = true
      const res = await GetWxUserInfo({openid:this.userInfo.openid});
      this.overlayLoading = false
      let { data,state } = res;
      if(!data || state!='ok')return alert('操作错误');
      const { gz_config, s_nickname, s_phone } = data[0];
      this.gz_config = JSON.parse(gz_config);
      this.formData = { s_nickname,s_phone }
      for (const key in this.portraitList) {
        var item = this.portraitList[key].actions.filter(it=>{
          return it.id === this.gz_config[key];
        })[0]
         this.portraitList[key].portrait =  item.text;
      }
      console.log(res)
    },
    // 确认
    baocunModel(){
      if(this.$refs.form){
        this.$refs.form.validate().then(_=>{
            console.log(this.formData);
            console.log(this.gz_config);
            let obj = Object.assign({ openid: this.userInfo.openid,gz_config:JSON.stringify(this.gz_config) },this.formData)
            console.log(obj)
            this.overlayLoading = true
            SetWxUserInfo(obj).then(res=>{
              console.log(res)
              // if(res.state == 'ok') {
              //   alert('设置成功')
              // }
              this.overlayLoading = false
              this.showcancel = false
            })
        });
      }else{
          if(!this.formData.s_nickname) return this.showcancel = true;
          if(!this.formData.s_phone) return this.showcancel = true;
          let obj = Object.assign({ openid: this.userInfo.openid,gz_config:JSON.stringify(this.gz_config) },this.formData)
          console.log(obj);
          this.overlayLoading = true
          SetWxUserInfo(obj).then(res=>{
            console.log(res)
            // if(res.state == 'ok') {
            //   alert('设置成功')
            // }
            this.overlayLoading = false
            this.showcancel = false
          })
      }
      // this.showcancel = false
    },
    // 获取 用户信息
    async GetUserData(){
      const { openid, pic, nickname } = this.$route.query;
      this.userInfo = {openid, pic, nickname};
      console.log(openid,pic,nickname)
    },
    // 已订阅/未订阅
    showDropDown(e, item) {
      console.log(e,item)
      item.portrait = e.text;
      this.gz_config[item.key] = e.id
      this.baocunModel()
    },

  },

  filters: {

  }
}
</script>
<style>
.van-dialog__header{
  color: #333;
}
</style>
<style lang="scss" scoped>
.mianModel{
  color: #333;
  margin: 30px 0;
  padding: 0 10px;
}
.btnsModel{
  display: flex;
  .van-button {
    width: 50%;
  }
}
.Subscribe {
  padding: 8px;
  box-sizing: border-box;

  .portrait-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 13px;
    }
  }
  .dy_zt::before {
          content: "  ";
          margin-right: 5px;
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: rgba($color: #FEAD3B, $alpha: 1.0);
        
      }
  .ydy_zt::before {
          content: "  ";
          margin-right: 5px;
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: rgba($color: #1CD66C, $alpha: 1.0);
        
      }
  .sk_remind {
    margin-top: 15px;
    font-size: 14px;
    background-color: rgba($color: #000000, $alpha: 0.3);

    .tilte {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
      padding: 10px;

      ol {
        display: flex;
        .dy_state::before {
          content: "  ";
          margin-right: 5px;
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: rgba($color: #1CD66C, $alpha: 1.0);
        
      }
        li {
          margin-left: 10px;
        }

        li::before {
          content: "  ";
          margin-right: 5px;
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: rgba($color: #FEAD3B, $alpha: 1.0);
        }
      }
    }

    .portrait_list {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      justify-content: space-between;
      text-align: center;

      >li {
        width: 31%;
      }

      li.list_item {
        margin-bottom: 10px;
        height: 120px;
        background-color: rgba($color: #fff, $alpha: 0.1);
        border-radius: 5px;

        .what_el {
          height: 80%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            margin-bottom: 10px;
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }

  .drop-down-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    span{white-space:nowrap;}
    .drop-down {
      margin-left: 5px;
      transform: rotate(90deg);
    }
  }

  .portrait_config {

    font-size: 14px;
    background-color: rgba($color: #000000, $alpha: 0.3);

    p {
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);
    }

    .box {
      padding: 20px 10px 0px;

      li {
        display: flex;
        align-items: center;
        padding: 8px 15px;

        span {
          flex: 2;
        }
      }

      li:not(:last-child) {

        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);
      }
    }

    .tilte {
      font-size: 15px;
      padding: 5px 10px;
      margin: 0;
    }
  }

  .user_ifon {
    display: flex;
    align-items: center;
    margin: 20px 20px;

    .head-portrait {
      width: 60px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 20px;
      img {
        width: 100%;
      }
    }
  }
}
</style>